import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ScaledImage from './components/ScaledImage';
import './Home.scss';
import aiImage from './images/home/ai.png';
import bigDataImage from './images/home/big-data.png';
import cloudComputingImage from './images/home/cloud-computing.png';
import damoyuanLogoImage from './images/home/damoyuan-logo.png';
import damoyuanxuantieImage from './images/home/damoyuanxuantie.png';
import dataCenterImage from './images/home/data-center.png';
import dianxinLogoImage from './images/home/dianxin-logo.png';
import eswinLogoImage from './images/home/eswin-logo.png';
import hanwujiLogoImage from './images/home/hanwuji-logo.png';
import hanwujiImage from './images/home/hanwuji.png';
import hoverFeature1Image from './images/home/hover-feature-1.png';
import hoverFeature2Image from './images/home/hover-feature-2.png';
import hoverFeature3Image from './images/home/hover-feature-3.png';
import indexImage from './images/home/index.png';
import intelligentComputingImage from './images/home/intelligent-computing.png';
import lanzhouImage from './images/home/lanzhou.png';
import nanfangkejiImage from './images/home/nanfangkeji.png';
import openSoruceSoftwareImage from './images/home/open-source-software.png';
import saifangImage from './images/home/saifang.png';
import shandongImage from './images/home/shandong.png';
import suannengLogoImage from './images/home/suanneng-logo.png';
import suannengImage from './images/home/suanneng.png';
import { LocaleFileContext } from './locale';

const Home: React.FC = (): React.ReactElement => {
  const [expandedIndex, setExpandedIndex] = useState<string>('1');
  const [lockedAnim, setLockedAnim] = useState<boolean>(false);
  const localeFile = useContext(LocaleFileContext);

  const handleMouseEnter = (index: string) => {
    setExpandedIndex(index);
  }

  useEffect(() => {
    setLockedAnim(true);
    const timer = setTimeout(() => {
      setLockedAnim(false);
    }, 280);
    return () => clearTimeout(timer);
  }, [expandedIndex]);

  return (
    <div className="home-wrapper">
      <div className="block-1">
        <ScaledImage img={indexImage} defaultHeight={730}>
          <div className="title">{localeFile['home.block-1-title-row-1']}<br />{localeFile['home.block-1-title-row-2']}</div>
          <div className="description">{localeFile['home.block-1-description']}</div>
          <Link to="/download">
            <Button type="primary" size="large" className="download-button">{localeFile['layout.download']} EulixOS</Button>
          </Link>
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <div className="title">EulixOS</div>
          <div className="description">{localeFile['home.block-2-description']}</div>
          <div className="feature-block feature-block-left">
            <img className="block-img" src={openSoruceSoftwareImage} />
            <div className="block-title">{localeFile['home.block-2-col-title-1']}</div>
          </div>
          <div className="feature-block feature-block-center">
            <img className="block-img" src={dataCenterImage} />
            <div className="block-title">{localeFile['home.block-2-col-title-2']}</div>
          </div>
          <div className="feature-block feature-block-right">
            <img className="block-img" src={intelligentComputingImage} />
            <div className="block-title">{localeFile['home.block-2-col-title-3']}</div>
          </div>
        </ScaledImage>
      </div>
      <div className="block-3">
        <ScaledImage defaultHeight={810}>
          <div className="title">{localeFile['home.block-3-title']}</div>
          <div className="block-img img-1">
            <img className="img" src={damoyuanxuantieImage} />
          </div>
          <div className="block-img img-2">
            <img className="img" src={hanwujiImage} />
          </div>
          <div className="block-img img-3">
            <img className="img" src={suannengImage} />
          </div>
          <div className="block-img img-4">
            <img className="img" src={saifangImage} />
          </div>
        </ScaledImage>
      </div>
      <div className="block-4">
        <ScaledImage defaultHeight={810}>
          <div className="title">{localeFile['home.block-4-title']}</div>
          <div className="description">{localeFile['home.block-4-description']}</div>
          <div className="feature-wrapper">
            <div className={`feature-block ${expandedIndex === '1' && 'hovered-block'}`} style={{ backgroundImage: `url(${hoverFeature1Image})` }} onMouseEnter={() => handleMouseEnter('1')}>
              <img className="feature-icon" src={aiImage} />
              <div className="feature-title">{localeFile['home.block-4-ai-title']}</div>
              {
                !lockedAnim && expandedIndex === '1' && (
                  <>
                    <div className="feature-content">{localeFile['home.block-4-ai-1']}</div>
                    <div className="feature-content">{localeFile['home.block-4-ai-2']}</div>
                  </>
                )
              }
            </div>
            <div className={`feature-block ${expandedIndex === '2' && 'hovered-block'}`} style={{ backgroundImage: `url(${hoverFeature2Image})` }} onMouseEnter={() => handleMouseEnter('2')}>
            <img className="feature-icon" src={bigDataImage} />
              <div className="feature-title">{localeFile['home.block-4-bigdata-title']}</div>
              {
                !lockedAnim && expandedIndex === '2' && (
                  <>
                    <div className="feature-content">{localeFile['home.block-4-bigdata-1']}</div>
                    <div className="feature-content">{localeFile['home.block-4-bigdata-2']}</div>
                  </>
                )
              }
            </div>
            <div className={`feature-block ${expandedIndex === '3' && 'hovered-block'}`} style={{ backgroundImage: `url(${hoverFeature3Image})` }} onMouseEnter={() => handleMouseEnter('3')}>
            <img className="feature-icon" src={cloudComputingImage} />
              <div className="feature-title">{localeFile['home.block-4-cloudcomputing-title']}</div>
              {
                !lockedAnim && expandedIndex === '3' && (
                  <>
                    <div className="feature-content">{localeFile['home.block-4-cloudcomputing-1']}</div>
                    <div className="feature-content">{localeFile['home.block-4-cloudcomputing-2']}</div>
                    <div className="feature-content">{localeFile['home.block-4-cloudcomputing-3']}</div>
                  </>
                )
              }
            </div>
          </div>
        </ScaledImage>
      </div>
      <div className="block-5">
        <ScaledImage defaultHeight={647}>
          <div className="title">{localeFile['home.block-5-title']}</div>
          <div className="subtitle subtitle-1">{localeFile['home.block-5-subtitle-1']}</div>
          <img className="big-icon big-icon-1" src={shandongImage} />
          <img className="big-icon big-icon-2" src={lanzhouImage} />
          <img className="big-icon big-icon-3" src={nanfangkejiImage} />
          <div className="subtitle subtitle-2">{localeFile['home.block-5-subtitle-2']}</div>
          <img className="small-icon small-icon-1" src={dianxinLogoImage} />
          <img className="small-icon small-icon-2" src={damoyuanLogoImage} />
          <img className="small-icon small-icon-3" src={eswinLogoImage} />
          <img className="small-icon small-icon-4" src={suannengLogoImage} />
          <img className="small-icon small-icon-5" src={hanwujiLogoImage} />
        </ScaledImage>
      </div>
    </div>
  );
};

Home.displayName = 'Home';

export default Home;
