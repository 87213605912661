import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ScaledImage from './components/ScaledImage';
import aiImage from './images/products/ai.png';
import bigDataImage from './images/products/big-data.png';
import cloudComputingImage from './images/products/cloud-computing.png';
import dataCenterImage from './images/products/data-center.png';
import maturityImage from './images/products/maturity.png';
import productsIndexImage from './images/products/products-index.png';
import seamlessCompatibilityImage from './images/products/seamless-compatibility.png';
import { LocaleFileContext } from './locale';
import './Products.scss';

const Products: React.FC = () => {
  const [dataShowIndexA, setDataShowIndexA] = useState(0);
  const [dataShowIndexB, setDataShowIndexB] = useState(0);
  const localeFile = useContext(LocaleFileContext);

  return (
    <div className="products-wrapper">
      <div className="block-1">
        <ScaledImage img={productsIndexImage} defaultHeight={730}>
          <div className="title">EulixOS 3.0</div>
          <div className="description">{localeFile['products.block-1-description']}</div>
          <Link to="/download">
            <Button type="primary" size="large" className="download-button">{localeFile['layout.download']} EulixOS</Button>
          </Link>
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <div className="left-block text-block">
            <div className="title">{localeFile['products.block-2-title']}</div>
            <div className="content-wrapper">
              <div className="content-para">{localeFile['products.block-2-description-1']}</div>
              <div className="content-para">{localeFile['products.block-2-description-2']}</div>
            </div>
          </div>
          <img className="right-block" src={dataCenterImage} />
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <img className="left-block" src={seamlessCompatibilityImage} />
          <div className="right-block text-block">
            <div className="title">{localeFile['products.block-3-title']}</div>
            <div className="content-wrapper">
              <div className="content-para">{localeFile['products.block-3-description-1']}</div>
              <div className="content-para">{localeFile['products.block-3-description-2']}</div>
            </div>
          </div>
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <div className="left-block text-block">
            <div className="title">{localeFile['products.block-4-title']}</div>
            <div className="content-wrapper">
              <div className="content-para">{localeFile['products.block-4-description']}</div>
            </div>
          </div>
          <img className="right-block" src={maturityImage} />
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <img className="left-block" src={aiImage} />
          <div className="right-block text-block">
            <div className="title">{localeFile['products.block-5-title']}</div>
            <div className="content-wrapper">
              <div className={`content-para-wrapper ${dataShowIndexA !== 0 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-5-description-1']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexA !== 1 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-5-description-2']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexA !== 2 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-5-description-3']}</div>
              </div>
            </div>
            <div className="index-bar-container">
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexA(0)}>
                <div className={`index-bar ${dataShowIndexA === 0 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexA(1)}>
                <div className={`index-bar ${dataShowIndexA === 1 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexA(2)}>
                <div className={`index-bar ${dataShowIndexA === 2 ? 'activity-bar' : ''}`} />
              </div>
            </div>
          </div>
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <div className="left-block text-block">
            <div className="title">{localeFile['products.block-6-title']}</div>
            <div className="content-wrapper">
              <div className="content-para">{localeFile['products.block-6-description-1']}</div>
              <div className="content-para">{localeFile['products.block-6-description-2']}</div>
            </div>
          </div>
          <img className="right-block" src={bigDataImage} />
        </ScaledImage>
      </div>
      <div className="block-2">
        <ScaledImage defaultHeight={810}>
          <img className="left-block" src={cloudComputingImage} />
          <div className="right-block text-block">
            <div className="title">{localeFile['products.block-7-title']}</div>
            <div className="content-wrapper">
              <div className={`content-para-wrapper ${dataShowIndexB !== 0 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-7-description-1']}</div>
                <div className="content-para">{localeFile['products.block-7-description-2']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexB !== 1 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-7-description-3']}</div>
                <div className="content-para">{localeFile['products.block-7-description-4']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexB !== 2 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-7-description-5']}</div>
                <div className="content-para">{localeFile['products.block-7-description-6']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexB !== 3 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-7-description-7']}</div>
                <div className="content-para">{localeFile['products.block-7-description-8']}</div>
              </div>
              <div className={`content-para-wrapper ${dataShowIndexB !== 4 ? 'display-none-content' : ''}`}>
                <div className="content-para">{localeFile['products.block-7-description-9']}</div>
              </div>
            </div>
            <div className="index-bar-container">
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexB(0)}>
                <div className={`index-bar ${dataShowIndexB === 0 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexB(1)}>
                <div className={`index-bar ${dataShowIndexB === 1 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexB(2)}>
                <div className={`index-bar ${dataShowIndexB === 2 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexB(3)}>
                <div className={`index-bar ${dataShowIndexB === 3 ? 'activity-bar' : ''}`} />
              </div>
              <div className="index-bar-wrapper" onClick={() => setDataShowIndexB(4)}>
                <div className={`index-bar ${dataShowIndexB === 4 ? 'activity-bar' : ''}`} />
              </div>
            </div>
          </div>
        </ScaledImage>
      </div>
    </div>
  );
};

Products.displayName = 'Products';

export default Products;
