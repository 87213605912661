import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext } from 'react';
import ScaledImage from './components/ScaledImage';
import './Download.scss';
import ChipImage from './images/download/chip.png';
import { LocaleFileContext } from './locale';

interface IListItem {
  title: string
  content: string[]
}

interface ISubMenuItem {
  parentTitle: string
  title: string
  displayItems?: IListItem[]
  disabled?: boolean
  downloadLink?: string
}

interface IMenuItem {
  title: string
  children: ISubMenuItem[]
}

const menuItems: IMenuItem[] = [
  {
    title: 'Sophon',
    children: [
      {
        parentTitle: 'Sophon',
        title: 'SG2042',
        displayItems: [
          {
            title: 'Lastest release',
            content: ['EulixOS 3.0'],
          },
          {
            title: 'Models',
            content: ['SR1-2208', 'Milk-V Pioneer'],
          }
        ],
        downloadLink: 'https://mirror.iscas.ac.cn/eulixos/3.0/iso/EulixOS-3.0.iso',
      },
      {
        parentTitle: 'Sophon',
        title: 'SG2044',
        disabled: true,
      },
    ],
  },
  {
    title: 'T-Head XuanTie',
    children: [
      {
        parentTitle: 'T-Head XuanTie',
        title: 'C906',
        displayItems: [
          {
            title: 'Lastest release',
            content: ['EulixOS 2.0'],
          },
          {
            title: 'Models',
            content: ['Allwinner Nezha D1'],
          }
        ],
        downloadLink: 'https://mirror.iscas.ac.cn/eulixos/release/2/images/riscv64/EulixOS-D1-2.0-xfce-riscv64.img.zst',
      },
    ],
  },
  {
    title: 'StarFive',
    children: [
      {
        parentTitle: 'StarFive',
        title: 'JH7100',
        displayItems: [
          {
            title: 'Lastest release',
            content: ['EulixOS 2.0'],
          },
          {
            title: 'Models',
            content: ['StarFive VisionFive V1'],
          }
        ],
        downloadLink: 'https://mirror.iscas.ac.cn/eulixos/release/2/images/riscv64/EulixOS-D1-2.0-xfce-riscv64.img.zst',
      },
    ],
  },
  {
    title: 'SiFive',
    children: [
      {
        parentTitle: 'SiFive',
        title: 'U740',
        displayItems: [
          {
            title: 'Lastest release',
            content: ['EulixOS 2.0'],
          },
          {
            title: 'Models',
            content: ['SSiFive unmatched'],
          }
        ],
        downloadLink: 'https://mirror.iscas.ac.cn/eulixos/release/2/images/riscv64/EulixOS-D1-2.0-xfce-riscv64.img.zst',
      },
    ],
  },
];

const Download: React.FC = (): React.ReactElement => {
  const [currentItem, setCurrentItem] = React.useState<ISubMenuItem>(menuItems[0].children[0]);
  const localeFile = useContext(LocaleFileContext);

  return (
    <ScaledImage defaultHeight={974}>
      <div className="download-wrapper">
        <div className="top-info-block">
          <div className="top-title">Download Center</div>
          <div className="top-desc">Provide download images for different RISC-V hardware platforms</div>
        </div>
        <div className="detail-block">
          <div className="menu-wrapper">
            {menuItems.map((menuItem, index) => (
              <div key={`title-${index}`}>
                <div className="menu-item">{menuItem.title}</div>
                {menuItem.children.map((subMenuItem, subIndex) => (
                  <div
                    key={`sub-title-${subIndex}`}
                    className={`sub-menu-item ${currentItem.title === subMenuItem.title ? 'active' : ''} ${subMenuItem.disabled ? 'disabled' : ''}`}
                    onClick={() => !subMenuItem.disabled && setCurrentItem(subMenuItem)}
                  >
                    {subMenuItem.title}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="item-detail-wrapper">
            <div className="item-title">{currentItem.parentTitle}&nbsp;-&nbsp;{currentItem.title}</div>
            <img className="item-img" src={ChipImage} />
            <div className="item-content-block one-row">
              <div className="item-content-title">{currentItem.displayItems?.[0].title}&nbsp;:&nbsp;</div>
              <div className="item-content-value">
                {currentItem.displayItems?.[0].content.map((content, contentIndex) => (
                  <div key={`content-${contentIndex}`} className="item-content-item">{content}</div>
                ))}
              </div>
            </div>
            <div className="item-content-block">
              <div className="item-content-title">{currentItem.displayItems?.[1].title}&nbsp;:&nbsp;</div>
              <div className="item-content-value">
                {currentItem.displayItems?.[1].content.map((content, contentIndex) => (
                  <div key={`content-${contentIndex}`} className="item-content-item">-&nbsp;{content}</div>
                ))}
              </div>
            </div>
            <Button className="download-button" type="primary" icon={<CloudDownloadOutlined style={{ fontSize: 18, lineHeight: 24 }} />} size="large" onClick={() => window.open(currentItem.downloadLink, '_blank')}>
              {localeFile['layout.download']}
            </Button>
          </div>
        </div>
      </div>
    </ScaledImage>
  );
};

Download.displayName = 'Download';

export default Download;
