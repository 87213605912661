import React, { useEffect, useMemo } from 'react';
import './ScaledImage.scss';

interface IProps {
  img?: string
  defaultHeight?: number
  children?: React.ReactNode
  maxRatio?: number
}

const ScaledImage: React.FC<IProps> = ({ img, children, defaultHeight, maxRatio }) => {
  const [screenWidth, setScreenWidth] = React.useState(document.documentElement.clientWidth || document.body.clientWidth);


  const scaledRatio = useMemo(() => {
    return maxRatio ? Math.min(screenWidth / 1440, maxRatio) : screenWidth / 1440;
  }, [screenWidth]);

  const handleWindowResize = () => {
    setScreenWidth(document.documentElement.clientWidth || document.body.clientWidth);
  }

  useEffect(() => {
    setScreenWidth(document.documentElement.clientWidth || document.body.clientWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="scaled-container" style={{ minHeight: `${(defaultHeight ?? 0) * scaledRatio}px`}}>
      {
        img
          ? <img className="origin-size-image" src={img} />
          : <div className="placeholder" style={{ height: `${(defaultHeight ?? 0) * scaledRatio}px`}} />
      }
      <div className="origin-size-wrapper" style={{ transform: `translate(-50%, -50%) scale(${scaledRatio})`, width: `${1 / scaledRatio * 100}%`, height: `${1 / scaledRatio * 100}%`}}>
        {children}
      </div>
    </div>
  );
}

export default ScaledImage;
