import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import logo from './images/logo@3x.png';
import './Layout.scss';
import { LocaleContext, LocaleDispatchContext, LocaleFileContext } from './locale';
import ENImage from './images/EN.png';
import CHImage from './images/CH.png';
import ScaledImage from './components/ScaledImage';

const { Header } = Layout;

const App: React.FC = () => {
  const locale = useContext(LocaleContext);
  const dispatchLocale = useContext(LocaleDispatchContext);
  const localeFile = useContext(LocaleFileContext);

  return (
    <Layout>
      <ScaledImage defaultHeight={80} maxRatio={1}>
        <Header className="eulix-header">
          <Link to="/">
            <img className="demo-logo" src={logo} />
          </Link>
          <div className="eulix-header-menu">
            <Link to="products">
              <div className="eulix-menu-item">{localeFile['layout.products']}</div>
            </Link>
            <Link to="support/en-US_index">
              <div className="eulix-menu-item">{localeFile['layout.support']}</div>
            </Link>
            <Link to="download">
              <div className="eulix-menu-item">{localeFile['layout.download']}</div>
            </Link>
          </div>
          <div className="eulix-header-right">
            <img className="locale" onClick={() => dispatchLocale({ type: 'change', payload: locale === 'en-US' ? 'zh-Hans' : 'en-US' })} src={locale === 'en-US' ? ENImage : CHImage }/>
          </div>
        </Header>
      </ScaledImage>
      <div className="content">
        <Outlet />
      </div>
      <ScaledImage defaultHeight={163} maxRatio={1}>
      <div className="eulix-footer">
        <div className="footer-nav-row">
          <Link to="/">
            <div className="footer-nav-item" style={{ marginRight: 11, marginLeft: 8 }}>{localeFile['layout.home']}</div>
          </Link>
          <Link to="products">
            <div className="footer-nav-item" style={{ marginRight: 15 }}>{localeFile['layout.products']}</div>
          </Link>
          <Link to="support">
            <div className="footer-nav-item" style={{ marginRight: 25 }}>{localeFile['layout.support']}</div>
          </Link>
          <Link to="download">
            <div className="footer-nav-item" style={{ marginRight: 12 }}>{localeFile['layout.download']}</div>
          </Link>
        </div>
        <div className="copyright">京ICP备05046678号-16&nbsp;•&nbsp;Copyright© {localeFile['layout.copyright']}</div>
      </div>
      </ScaledImage>
    </Layout>
  );
};

export default App;
