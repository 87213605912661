import { Affix, Menu, MenuProps } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import docsDetail from './docs.json';
import { LocaleContext, LocaleDispatchContext } from './locale';
import parseMD from 'parse-md';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from "rehype-slug";
import rehypeToc from "rehype-toc";
import 'github-markdown-css/github-markdown-light.css'

import './Support.scss';
import { useParams } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

interface IFileItem {
  path: string
  name: string
  type: string
  isIndex?: boolean
  children?: IFileItem[]
}

interface IFileAssetItem {
  item: string;
  path: string;
}

const Support: React.FC = (): React.ReactElement => {
  const locale = useContext(LocaleContext);
  const dispatchLocale = useContext(LocaleDispatchContext);

  const [imgAssetArray, setImgAssetArray] = useState<IFileAssetItem[]>([]);
  const [fileAssetArray, setFileAssetArray] = useState<IFileAssetItem[]>([]);
  const [mdText, setMdText] = useState<string>('');
  const [currentMdUrl, setCurrentMdUrl] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const { key } = useParams();
  const [currentKey, setCurrentKey] = useState<string>(key || '');
  const currentMenuKey = useMemo(() => `${currentKey?.split('_').join('/')}.md`, [currentKey]);
  const currentMenuOpenKeys = useMemo(() => {
    const keyArray = currentKey?.split('_') ?? []
    let temp = ''
    return keyArray.map((item) => {
      if (temp === '') {
        temp = item
      } else {
        temp += `/${item}`
      }
      return temp
    })
  }, [currentKey]);

  useEffect(() => {
    const imgArray: IFileAssetItem[] = []
    const fileArray: IFileAssetItem[] = []

    const handleFile = async (children: IFileItem[]) => {
      await Promise.all(
        children.map(async (child) => {
          if (child.type === 'folder' && child.children) {
            await handleFile(child.children)
          } else if (child.path.match(/(.png|.jpg|.gif)/g)) {
            const item = await import(`./markdown/${child.path}`)
            imgArray.push({
              item: item.default,
              path: child.path,
            })
          } else if (child.path.match(/.md/g)) {
            const item = await import(`./markdown/${child.path}`)
            const mdFile = await fetch(item.default)
            const mdText = await mdFile.text()
            fileArray.push({
              item: mdText,
              path: child.path,
            })
          }
        })
      )
    }

    handleFile(docsDetail.children).then(() => {
      setImgAssetArray(imgArray);
      setFileAssetArray(fileArray);
    })

    const urlLocale = currentKey.split('_')[0]
    if (urlLocale !== locale) {
      dispatchLocale({ type: 'change', payload: urlLocale as 'en-US' | 'zh-Hans' })
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    } else {
      window.history.pushState({}, '', `/support/${locale}_index`);
      setCurrentKey(`${locale}_index`);
    }
  }, [locale]);

  useEffect(() => {
    const target = fileAssetArray.find(item => item.path === currentMenuKey)
    const md = target?.item
    setMdText(parseMD(md ?? '').content);
    setCurrentMdUrl(target?.path ?? '');
  }, [currentMenuKey, fileAssetArray, locale]);


  const localeMenu: MenuItem[] = useMemo(() => {
    const firstMenu = docsDetail.children.find(item => item.name === locale)

    const createMenuItems = (menuArray: IFileItem[]): MenuItem[] => {
      const isIndexItem = menuArray.find((item) => item.isIndex)

      const sortArray = isIndexItem
        ? [isIndexItem, ...menuArray.filter((item) => !item.isIndex)]
        : menuArray
      return sortArray
        .filter((fileItem) =>
          fileItem.type !== 'folder' ||
          (fileItem.children &&
          fileItem.children.some((subItem) => (subItem.isIndex || subItem.type === 'folder'))))
        .map((fileItem) => {
          return {
            key: fileItem.path,
            label: fileItem.type === 'folder' && fileItem?.children?.some((child) => child.isIndex)
              ? fileItem?.children?.find((child) => child.isIndex)?.name
              : fileItem.name,
            children: fileItem.children && createMenuItems(fileItem.children)
          }
        })
    }

    return createMenuItems(firstMenu?.children ?? []) ?? []
  }, [locale]);

  const onClick: MenuProps['onClick'] = (e) => {
    const target = fileAssetArray.find(item => item.path === e.key)
    const md = target?.item
    window.history.pushState({}, '', `/support/${e.key.replace(/.md/, '').split('/').join('_')}`);

    setMdText(parseMD(md ?? '').content);
    setCurrentMdUrl(target?.path ?? '');
    window.scrollTo(0, 240);
  };

  return (
    <div className="support-wrapper">
      <div className="top-info-block">
        <div className="top-title">Support</div>
        <div className="top-desc">Browse the latest EulixOS technical documentation</div>
      </div>
      <div className="docs-wrapper">
        <Affix>
          <div>
            <Menu
              className="support-menu"
              defaultSelectedKeys={[currentMenuKey]}
              defaultOpenKeys={currentMenuOpenKeys}
              onClick={onClick}
              mode="inline"
              items={localeMenu}
            />
          </div>
        </Affix>
        <div className="markdown-wrapper markdown-body">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw, rehypeSlug, rehypeToc]}
            components={{
              img: (props) => <img {...props} style={{ maxWidth: '100%' }} />,
              code: ({ children, ...props }) => <code {...props} style={{ maxWidth: '100%' }}>{children}</code>,
            }}
            urlTransform={(src) => {
              if (src.match(/.\//)) {
                const baseUrl = currentMdUrl.split('/').slice(0, -1).join('/')
                const relativeUrl = src.replace(/.\//, '')

                const imageUrl = `${baseUrl}/${relativeUrl}`
                const target = imgAssetArray.find((item) => item.path === imageUrl)?.item
                return target
              }
              return src
            }}
          >{mdText}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
};

Support.displayName = 'Support';

export default Support;
