import { ConfigProvider } from 'antd';
import React, { useMemo, useReducer } from 'react';
import { LocaleContext, LocaleDispatchContext, LocaleFileContext } from './locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import EN from './locale/en-US'
import CH from './locale/zh-Hans'

interface IProps {
  children: React.ReactNode
}

const localeReducer = (local: 'en-US' | 'zh-Hans', action: { type: string, payload: 'en-US' | 'zh-Hans' }) => {
  switch (action.type) {
    case 'change':
      return action.payload;
    default:
      return local;
  }
}

const localeMap = {
  'en-US': enUS,
  'zh-Hans': zhCN,
}

const Root: React.FC<IProps> = ({ children }): React.ReactElement => {
  const [locale, localeDispatch] = useReducer(localeReducer, 'en-US' as 'en-US' | 'zh-Hans');
  const localeFile = useMemo(() => {
    const fileMap = {
      'en-US': EN,
      'zh-Hans': CH,
    };
    return fileMap[locale];
  }, [locale]);

  return (
    <LocaleContext.Provider value={locale}>
      <LocaleDispatchContext.Provider value={localeDispatch}>
        <LocaleFileContext.Provider value={localeFile}>
          <ConfigProvider locale={localeMap[locale] ?? enUS} theme={{ token: { colorPrimary: '#315efb' } }}>
            {children}
          </ConfigProvider>
        </LocaleFileContext.Provider>
      </LocaleDispatchContext.Provider>
    </LocaleContext.Provider>
  );
}

Root.displayName = 'Root';

export default Root;
