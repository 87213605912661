export default {
  'layout.home': '主页',
  'layout.products': '产品',
  'layout.support': '支持',
  'layout.download': '下载',
  'layout.copyright': '2024 中国科学院软件研究所',
  'home.block-1-title-row-1': '智算未来',
  'home.block-1-title-row-2': '',
  'home.block-1-description': 'EulixOS 是基于 openEuler 的 RISC-V 操作系统，支持在主流 RISC-V 平台上工作。利用开源软件生态与定制化设计，打造高性能 Linux 发行版，迎接智算时代。',
  'home.block-2-description': 'EulixOS 专注于移植和构建基于 RISC-V 架构的 Linux 内核和开源操作系统发行版，并在 RISC-V 上推广数据中心开源软件生态系统。',
  'home.block-2-col-title-1': '开源软件',
  'home.block-2-col-title-2': '数据中心',
  'home.block-2-col-title-3': '智能计算',
  'home.block-3-title': '硬件支持',
  'home.block-4-title': '赋能 AI 与云端',
  'home.block-4-description': '支持更多 RISC-V Native 的 AI、大数据与云计算软件，完善的软件生态助您无压解决智能时代的业务部署需求。',
  'home.block-4-ai-title': '人工智能',
  'home.block-4-ai-1': '1. EulixOS 带来主流 AI 框架与计算库的 RISC-V V 指令扩展优化，助力您的计算业务。',
  'home.block-4-ai-2': '2. EulixOS 有丰富的供应商生态支持，一键使用供应商工具链获取关键硬件特性。',
  'home.block-4-bigdata-title': '大数据',
  'home.block-4-bigdata-1': '1. EulixOS 现在支持多种数据库与大数据处理框架，构建强大大数据生态体系。',
  'home.block-4-bigdata-2': '2. 更好的 Java 库优化，提升 Java 应用的性能、数据安全与容错能力。',
  'home.block-4-cloudcomputing-title': '云计算',
  'home.block-4-cloudcomputing-1': '1. 整合主流的的开源云服务软件，构建完善的 RISC-V 云计算生态。',
  'home.block-4-cloudcomputing-2': '2. RISC-V H 指令扩展支持现已加入 EulixOS 软件生态，提升云虚拟化性能。',
  'home.block-4-cloudcomputing-3': '3. EulixOS 带来 Istio on RISC-V，助您轻松构建微服务应用。',
  'home.block-5-title': '合作单位',
  'home.block-5-subtitle-1': '高校',
  'home.block-5-subtitle-2': '企业',
  'products.block-1-description': 'EulixOS 专注于移植和构建基于 RISC-V 架构的 Linux 内核和开源操作系统发行版，并在 RISC-V 上推广数据中心开源软件生态系统。',
  'products.block-2-title': '数据中心',
  'products.block-2-description-1': '支持部署在 Dual SOPHGO SG2042 @2.2GHz *64 组成的集群上。',
  'products.block-2-description-2': '提供 250+ 个数据中心 RISC-V Native 软件包',
  'products.block-3-title': '无缝兼容 openEuler',
  'products.block-3-description-1': 'EulixOS 是 openEuler 的衍生发行版，助您无缝从 openEuler 完成迁移。',
  'products.block-3-description-2': 'EulixOS 3.0 的工具链与开发库版本、ABI 与 openEuler 24.03 LTS 保持完全兼容。',
  'products.block-4-title': '成熟度持续演进',
  'products.block-4-description': 'EulixOS 3.0 提供数据中心重点软件成熟度追踪平台，与 openEuler 24.03 LTS ARM 平台上的软件进行迭代对比，提供软件成熟度度量。',
  'products.block-5-title': '人工智能',
  'products.block-5-description-1': 'EulixOS 在 AI 计算领域针对 RISC-V 架构进行深度优化，全面集成并适配了 PyTorch、Apache Mahout、Scikit-Learn、Apache Spark Mllib、MXNet、Caffe、MNN、FFmpeg、OpenBLAS、OpenCV、CNTK 和 ONNX 等多个主流开源机器学习框架和计算库，成为目前最全面支持AI框架和计算库的操作系统。这一突破性创新极大提升了 RISC-V 架构在数据中心 AI 应用中的支持力度与实用性',
  'products.block-5-description-2': 'EulixOS 充分发挥 RISC-V 架构的优势，通过 RVV（RISC-V 矢量扩展）对 AI 框架和计算库进行了深度优化，率先在 PyTorch 框架集成 RVV 特性，显著提升了其在 EulixOS 上的运行效率与计算性能。这一优化极大加速了 AI 任务的处理能力，为 AI 模型的高效推理与训练提供了强大的算力支持。',
  'products.block-5-description-3': 'EulixOS 全面整合了寒武纪（Cambricon）软件生态，成功适配了 Cambricon Neuware SDK、Cambricon PyTorch 框架、CATCH 库、Transformers 库以及多种大模型库。我们实现了如 ChatGLM(1/2/3)-6B、MiniCPM-2B 等大语言模型，以及 VisualGLM-6B 等多模态大模型的高效运行。这不仅极大丰富了数据中心 AI 应用的支持范围，还提供了多场景的加速处理能力，为 AI 部署的全面落地和规模化应用赋能。',
  'products.block-6-title': '大数据',
  'products.block-6-description-1': '多元大数据生态，含 Redis、OpenGauss 等数据库及 Hadoop、Spark 等大数据框架。',
  'products.block-6-description-2': 'Hadoop 通过原生库的支持，集成 zstd、isa-l 高效压缩算法，强化数据安全，提升效能',
  'products.block-7-title': '云计算',
  'products.block-7-description-1': 'RISC-V 作为开源指令集架构，逐渐受到云计算领域的重视和应用。EulixOS 的 RISC-V 云生态整合了主流开源云服务软件，构建了一个完整、高效的云计算环境，助力 RISC-V 在现代云服务中的应用发展。',
  'products.block-7-description-2': '全面开源迁移：所有软件均基于最新的开源版本进行了迁移与构建，确保了生态系统的开放性与前沿性。',
  'products.block-7-description-3': 'Televm（Stratovirt）支持：Televm 作为基于 Stratovirt 的轻量虚拟化方案，是首个基于 RISC-V 架构实现 H 扩展虚拟化的软件。它显著提升了虚拟化性能和资源管理效率，为云计算环境中的高效虚拟化提供了坚实基础。',
  'products.block-7-description-4': 'Istio 首次支持 RISC-V：Istio 服务网格作为微服务架构中的重要组件，首次在 RISC-V 平台上获得了完整支持，进一步推动了 RISC-V 在微服务架构中的应用。',
  'products.block-7-description-5': 'Docker 和 Kubernetes 支持：云原生的核心技术 Docker 和 Kubernetes 在 RISC-V 平台上得到了完整的支持。这为容器化部署和大规模集群管理提供了基础，用户能够在 RISC-V 平台上实现高效、灵活的容器管理和自动化调度。',
  'products.block-7-description-6': 'iSulad 轻量级容器运行时：iSulad 作为轻量级容器运行时，特别适合资源受限环境下的容器管理，为 RISC-V 的轻量级计算环境提供了更多的解决方案。',
  'products.block-7-description-7': 'Prometheus 和 Grafana 监控工具：Prometheus 作为强大的监控和告警系统，与 Grafana 配合使用，在 RISC-V 平台上为云计算环境提供了全面的监控和可视化解决方案。',
  'products.block-7-description-8': 'Helm 部署管理：Helm 作为 Kubernetes 的包管理工具，在 RISC-V 平台上实现了对复杂应用的自动化部署和管理，使得云计算应用的维护更加简洁高效。',
  'products.block-7-description-9': 'EulixOS 的 RISC-V 云计算生态在全面支持主流云计算组件的基础上，不断扩展其软件兼容性和虚拟化能力，特别是通过首次对 Istio 的支持以及首个兼容 H 扩展虚拟化的 Televm，RISC-V 平台为开发者和企业提供了更多的选择与可能。',
}
